
.LoginPage.body {
	width: 50%;
}

.LoginPage.footer {
	padding: 4px;
	text-align: center;
}

.LoginPage.footer span {
	color: #999;
	font-size: 13px;
}

.LoginPage .info {
	width: 100%;
	margin-top: 8px;
	color: #fcfcfc;
	background: #e27c00;
	padding: 18px 48px;
	text-align: center;
}


@media screen and (max-width: 660px) {

	.LoginPage.body {
		width: 90%;
	}
}