.LoginPage.body {
  width: 50%;
}

.LoginPage.footer {
  text-align: center;
  padding: 4px;
}

.LoginPage.footer span {
  color: #999;
  font-size: 13px;
}

.LoginPage .info {
  width: 100%;
  color: #fcfcfc;
  text-align: center;
  background: #e27c00;
  margin-top: 8px;
  padding: 18px 48px;
}

@media screen and (max-width: 660px) {
  .LoginPage.body {
    width: 90%;
  }
}

/*# sourceMappingURL=index.16b4c8fe.css.map */
